.service-container {
  background-color: #031d44;
  position: relative;
}

.service-img {
  padding: 100px; /* Adjust padding for better layout */
  max-width: 90%; /* Ensure the image fits within its container */
}

.service-content {
  position: relative;
  z-index: 1;
  padding: 10px;
}

.service-content h1 {
  color: #00A79D;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.service-content p {
  color: white;
  font-size: 22px;
  line-height: 1.6;
}

.explore-more-services-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0; /* Fixed at the bottom of the container */
  left: 0;
  right: 0;
  z-index: 2; /* Keep above other elements */
}

.explore-more-services {
  font-size: 25px;
  color: #052f3f;
  font-style: italic;
  letter-spacing: 2px;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.explore-more-services .arrow-icon {
  display: inline-block;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.explore-more-services:hover .arrow-icon {
  transform: translateX(5px);
}

.exp-btn {
  background: linear-gradient(to right, rgba(100, 200, 200, 1), rgba(100, 200, 200, 1)),
              linear-gradient(to right, rgba(255, 0, 0, 1), rgba(255, 0, 180, 1), rgba(0, 100, 200, 1));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
}

.exp-btn:hover {
  background-size: 0 3px, 100% 3px;
}

@media (max-width: 990px) {
  .service-img {
    padding: 10px;
    max-width: 300px;
  }

  .service-content {
    text-align: center;
  }

  .service-content h1 {
    font-size: 35px;
  }

  .service-content p {
    font-size: 20px;
  }

  .explore-more-services {
    font-size: 20px; /* Adjust font size for smaller screens */
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: white;
}

.learn-btn {
  background: linear-gradient(to right, rgb(80, 153, 153), rgba(100, 200, 200, 1)),
              linear-gradient(to right, #28f6e5, rgb(101, 251, 126), #10f499);
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
}

.learn-btn:hover {
  background-size: 0 3px, 100% 3px;
}

.learn-more {
  font-size: 17px;
  color: #00A79D;
  font-style: italic;
  letter-spacing: 2px;
  font-weight: 500;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.learn-more .arrow-icon-btn {
  display: inline-block;
  margin-left: 10px;
  transition: transform 0.3s ease;
  font-size: 20px;
}

.learn-more:hover .arrow-icon-btn {
  transform: translateX(5px);
}
