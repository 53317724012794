/* SignUp.css */
.signup-outer {
    background: linear-gradient(135deg, #00A79D, #052f3f);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.signup-container {
    background: #f1f3f5;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.signup-form h2 {
    margin-bottom: 10px;
    color: #052f3f;
    font-weight: 600;
    font-size:38px;
}
.signup-form p{
    font-size: 18px;
    font-weight: 500;
    color: #00A79D;
}

.signup-form input {
    width: 100%;
    padding: 12px 15px;
    margin: 10px 0;
    border-radius: 10px;
    border: 1px solid #ddd;
    background: #f1f3f5;
    font-size: 16px;
    transition: all 0.3s ease;
}

.signup-form input:focus {
    border-color: #00A79D;
    box-shadow: 0 0 10px rgba(117, 224, 198, 0.5);
    outline: none;
}

.signup-form button {
    width: 50%;
    padding: 12px 15px;
    margin-top: 20px;
    border-radius: 30px;
    border: none;
    background-color: #052f3f;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.signup-form button:hover {
    background-color: #00A79D;
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.error-message {
    color: #e74c3c;
    margin-bottom: 10px;
}
