.insight-section{
    background-image: linear-gradient(to bottom,#FFFFFF,#90d8d2,#053545);
    /* background-color: #e1dfe6; */
}
.insight-content{
    padding-top: 60px;
    padding-bottom: 20px;
}
.insight-content h1{
    color: #046B65;
    font-size: 43px;
}
.insight-content h5{
    color:#199c92;
}
.swiper-container-blog {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }

.swiper-slide-blog {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }
.card {
    overflow: hidden;
    transition: transform 0.2s;
    max-height: 380px;
    max-width: 300px;   
}

.card:hover {
    transform: scale(1.05);
}
.card-title:hover{
    color:#00A79D;
    cursor: pointer;
}
.card-img-top{
    
    transition: transform 0.5s ease, filter 0.5s ease;
    width: 100%;
    height:auto;
    object-fit: cover;
}

/* Container styling */
.image-container {
    position: relative;
    overflow: hidden;
    border-radius: 10px;

}
.insight-row{
    padding: 5% 8% 5% 8%;
}
/* Overlay styling */
.overlay-card {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s ease;
    color: white;
}

/* Text styling */
.text {
    opacity: 0;
    transition: opacity 0.5s ease;
    max-width: 350px;
    font-size: 13px;
    text-align: center;
    
}

.explore-btn{
    font-size:18px;
    font-weight: 400;
    background:
      linear-gradient(
        to right,
        rgba(100, 200, 200, 1),
        rgba(100, 200, 200, 1)
      ),
      linear-gradient(
        to right,
        rgba(255, 0, 0, 1),
        rgba(255, 0, 180, 1),
        rgba(0, 100, 200, 1)
    );
    background-size: 100% 3px, 0 3px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;
  }
  .explore-btn:hover {
    background-size: 0 3px, 100% 3px;
  }

/* Hover effects */
.card:hover .card-img-top {
    transform: scale(1.1);
    filter: blur(5px);
}

.card:hover .overlay-card {
    opacity: 1;
}

.card:hover .text {
    opacity: 1;
}

.explore-more-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
}

.explore-more {
    font-size: 30px;
    color: white;
    font-style: italic;
    letter-spacing: 2px;
    font-weight: 400;
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.explore-more .arrow-icon {
    display: inline-block;
    margin-left: 10px;
    transition: transform 0.3s ease;
    font-size: 20px;
}

.explore-more:hover .arrow-icon {
    transform: translateX(5px);
}
 @media (min-width:680px ) and (max-width:1200px){
    .card-title{
        font-size: 16px;
    }
    .text{
        font-size: 13px;
    }
    .exp{
        font-size: 13px;
    }
    .custom-col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    }
    @media (max-width:680px){
        .insight-content h1{
            font-size:35px
        }
        .insight-content h5{
            font-size: 15px;
        }
    }
    
