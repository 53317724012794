.bg-contain{
    background: var(--Gradient-BG, linear-gradient(180deg, #F3F8F2 0%, #CFF5FF 50%, #BFE8E3 100%));
}
.service-header h2{
    color:#052f3f;
    font-size: 60px;
}
.service-header h6{
font-weight: 600;
font-size: 23px;
line-height: 44px;
}
.service-header p{
    font-weight: 500;
    font-size: 18px;
}
.collaborate-btn{
    background-color: #052f3f;
  color: white;
  position: relative;
  display: inline-block;
  height: 50px;
  width: 180px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  overflow: hidden;
  transition: color 0.4s ease-in-out;
  margin-top: 15px;
}
.collaborate-btn::before{
    content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #00A79D;
  z-index: 0;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.4s ease-in-out;
}
.collaborate-btn:hover::before {
    transform: scaleX(1);
    transform-origin: left;
  }
  
  .collaborate-btn span {
    position: relative;
    z-index: 1;
    transition: transform color 0.4s ease;
  }
  
  .collaborate-btn:hover span {
    color: white;
  }
  .heading-streamline h1{
    color: #052f3f;
    font-size: 50px;
    
  }
  .heading-streamline h6{
    font-size: 22px;
    font-weight: 400;
    margin-top: 10px;
  }
  .heading-streamline p{
    padding: 20px 30px;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 17px;
  }
  .timeline-container{
    background-color: #052f3f;
    border-radius: 6px;
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  }
  
  
  /* The actual timeline (the vertical ruler) */
  .timeline-container::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
  }
  
  /* Container around content */
  .timeline-item {
    padding: 10px 50px;
    position: relative;
    background-color: inherit;
    width: 50%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  
  /* The circles on the timeline */
  .timeline-item::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: #00A79D;
    border: 4px solid white;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }
  
  /* Place the container to the left */
  .left {
    left: 0;
  }
  
  /* Place the container to the right */
  .right {
    left: 50%;
  }
  
  /* Add arrows to the left container (pointing right) */
  .left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid white;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent white;
  }
  
  /* Add arrows to the right container (pointing left) */
  .right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }
  
  /* Fix the circle for containers on the right side */
  .right::after {
    left: -16px;
  }
  
  /* The actual content */
  .content {
    padding: 20px 30px;
    background-color: #00A79D;
    color: white;
    position: relative;
    border-radius: 6px;
   }
   .content h3{
    font-size: 25px;
   }
  
  /* Media queries - Responsive timeline on screens less than 600px wide */
  @media screen and (max-width: 768px) {
  /* Place the timelime to the left */
    .timeline-container::after {
      left: 31px;
    }
  
  /* Full-width containers */
    .timeline-item {
      width: 100%;
      padding-left: 70px;
      padding-right: 25px;
    }
  
  /* Make sure that all arrows are pointing leftwards */
    .timeline-item::before {
      left: 60px;
      border: medium solid white;
      border-width: 10px 10px 10px 0;
      border-color: transparent white transparent transparent;
    }
  
  /* Make sure all circles are at the same spot */
    .left::after, .right::after {
      left: 15px;
    }
  
  /* Make all right containers behave like the left ones */
    .right {
      left: 0%;
    }
    .content h3{
        font-size: 20px;
    }
  }
  
  .outer-tech{
    background-color: #00A79D;
    border-radius: 10px;
    padding: 30px 40px;
    margin-top: 30px ;
    margin-bottom: 50px;
  }
  .inner-tech{
    background-color: white;
    border-radius: 8px;
   padding: 20px;
  }
  .innovation-container{
    background-color: #00A79D;
    border-radius: 10px;
    padding: 20px 50px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
    
  }
  .innovation-container p{
    font-size: 18px;
  }
  
  .icon-img {
    width: 100%; /* Responsive width */
    height: auto; /* Maintain aspect ratio */
    max-width: 180px; /* Maximum size */
    max-height: 120px; /* Maximum size */
    object-fit: contain;
}
.icon-container {
    flex: 1 1 100px; /* Allow flex items to grow and shrink with a base size */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px; /* Space between icons */
}

.feature-container {
    background-color: #4bb9ae;
    border-radius: 10px;
    max-width: 700px;
    padding: 40px;
    margin-top: 80px;
    margin-bottom: 100px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
    flex: 1 1 100%; /* Make it responsive */
}

.feature-item h3 {
    color: #052f3f;
    font-weight: 800;
    font-size: 26px;
}

.feature-item p {
    color: white;
    font-weight: 500;
}

@media (max-width: 768px) {
    .feature-item h3 {
        font-size: 20px; /* Adjust font size for small screens */
    }

    .feature-item p {
        font-size: 14px; /* Adjust font size for small screens */
    }
    .feature-container{
        margin:20px 20px;
    }

    .image-wrapper {
        display: none;
    }
}
/* Timeline.css */
.timeline-services {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: transparent;
    position: relative;
}

.timeline-item-services {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    padding: 10px;
    margin: 5px 0;
    position: relative;
}


.timeline-item-services.left-side .content-services {
    order: 1;
    text-align: left;
}

.timeline-item-services.left-side .image-container-services {
    order: 2;
}

.timeline-item-services.right-side .content-services {
    order: 2;
    text-align: right;
}

.timeline-item-services.right-side .image-container-services {
    order: 1;
}

.content-services {
    flex: 1;
    padding: 20px;
}

.content-services h3 {
    color: #052f3f;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 10px;
}

.content-services p {
    color: black;
    font-weight: 500;
    line-height: 1.5;
}

.image-container-services {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.image-container-services img {
    width: 100%;
    height: auto;
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Connector line */
.timeline-services::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 4px;
    background-color: #00A79D;
    transform: translateX(-50%);
}


/* Responsive Design */
@media (max-width: 768px) {
    .timeline-item-services {
        flex-direction: column;
        align-items: flex-start;
    }

    .timeline-item-services.left-side .content-services,
    .timeline-item-services.right-side .content-services {
        text-align: left;
    }

    .timeline-item-services.left-side .image-container-services,
    .timeline-item-services.right-side .image-container-services {
        order: 2;
    }

    .timeline-services::before {
        left: 20px;
        transform: translateX(0);
    }
}
@media (max-width: 768px) {
    .heading-streamline h1 {
      font-size: 2.35rem; /* Smaller font size for h1 */
    }
     .heading-streamline h6 {
      font-size: 1.15rem; /* Smaller font size for h6 */
    }
    .innovation-img {
      display: none; /* Smaller image size */
    }
  }

  /* Media Query for very small screens */
  @media (max-width: 580px) {
    .heading-streamline h1{
      font-size: 1.75rem; /* Even smaller font size for h1 */
    }
    .heading-streamline h6 {
      font-size: 1rem; /* Even smaller font size for h6 */
    }
    .innovation-container{
        max-width:350px;
        padding:10px 20px
    }
    .innovation-container p{
        font-size: 16px;
    }
    .collaborate-btn{
        font-size: 18px;
    }
    .service-header h2{
        font-size: 40px;
    }
    .service-header h6{
        font-size: 22px;
    }
    .service-header p{
        font-size: 16px;
    } 
   .heading-streamline p{
    font-size: 15px;
   }
   .outer-tech{
    padding: 15px;
   }
   .content-services h3{
    font-size: 27px;
   }
   .content-services p{
    font-size: 15px;
   }
   .image-container-services{
    width: 80%;
   }
    
  }

  .loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
  }
  
  .loading-spinner {
    position: relative;
    width: 60px;
    height: 60px;
  }
  
  .loading-spinner::before,
  .loading-spinner::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    animation: spin 1.5s linear infinite;
  }
  
  .loading-spinner::before {
    width: 60px;
    height: 60px;
    border: 5px solid transparent;
    border-top: 5px solid #00A79D;
  }
  
  .loading-spinner::after {
    width: 40px;
    height: 40px;
    top: 10px;
    left: 10px;
    border: 5px solid transparent;
    border-bottom: 5px solid #052f3f;
    animation-duration: 1s;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Pulsating Glow Effect */
  .loading-glow {
    width: 12px;
    height: 12px;
    background-color: #052f3f;
    border-radius: 50%;
    margin-top: 10px;
    animation: pulse 1.5s infinite ease-in-out;
  }
  
  @keyframes pulse {
    0%, 100% {
      transform: scale(1);
      opacity: 0.5;
    }
    50% {
      transform: scale(1.5);
      opacity: 1;
    }
  }
  
  /* Loading Text Animation */
  .loading-text {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #00A79D;
    animation: fadeInOut 1.5s infinite ease-in-out;
  }
  
  @keyframes fadeInOut {
    0%, 100% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
  }
  