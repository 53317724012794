.scroll-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1000;
  }
  
  .scroll-button {
    background-color: transparent;
    border: none;
    color: rgb(98, 206, 155);
    padding: 12px 12px;
    font-size: 22px;
    border-radius: 40%;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    opacity: 0.9;
  }
  
  .scroll-button:hover {
    opacity: 1;
  }
  