.bg-project{
    background: var(--Gradient-BG, linear-gradient(180deg, #F3F8F2 0%, #CFF5FF 50%, #BFE8E3 100%));
}

.project-title{
    font-size: 3rem;
    color:#052f3f;
}

.responsive-item-container {
    gap: 18px; /* Adds space between items */
}

.item {
    min-width: 150px; /* Ensures e item has a minimum width */
    max-width: 280px; /* Restricts the maximum width */
    text-align: center; /* Centers the text within each item */
}
.item h4{
    font-size: 1.6rem;
}
.about-project-container{
    margin-top: 60px !important;
    color:#052f3f;
    background-color: #F3F8F2;
    border-radius: 10px;
    margin: 0 auto;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
}
/* .project-problem-container{
  margin-top: 60px !important;
  color:#052f3f;
  border-radius: 10px;
  margin: 0 auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
} */

.project-problem-container{
  margin-top: 60px !important;
  color:white;
  background-color: #052f3f;
  border-radius: 10px;
  margin: 0 auto;
}

.border-separator{
    width: 3px;
    background-color: #00A79D; /* Light gray color for the border */
    margin: 0 15px; /* Spacing between the border and text */
    align-self: stretch;
}
.about-project-heading h4{
    font-size: 21px;
    color: #00A79D;
}
.about-project-desc{
    font-size: 16px;
}

.technologies-grid {
  display: flex;
  justify-content: center;
  gap: 5rem;
  flex-wrap: wrap;
}

.technology-item {
  background-color: #F3F8F2;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 1.3rem;
  text-align: center;
  width: 170px;
  margin-top: 30px;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
}

.technology-item:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  /* background-color: #00A79D; */
}

.tech-image {
  margin-left: 22px;
  width: 70px;
  height: 70px;
  margin-bottom: 1.1rem;
  object-fit: contain;
  transition: transform 0.3s ease;
}
.technology-item:hover .tech-image {
  transform: rotate(10deg) scale(1.1);
}

.tech-name {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0.3rem;
}

.tech-role {
  font-size: 19px;
  font-weight: bold;
  color: #052f3f;
}


.seamless{
    font-size: 2.6rem;
    color:#052f3f;
}
.border-technical{
    border-bottom: 3px solid #00A79D;
}


.feature-list {
  list-style-type: none;
  padding-left: 30px;
  margin: 0;
}

.feature-item {
  font-size: 1.4rem;
  line-height: 1.6;
  margin-bottom: 15px;
  padding-left: 27px;
  position: relative;
  transition: all 0.3s ease;
}

.feature-item::before {
  content: "•";
  font-size: 30px;
  color: #00A79D;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-4px);
}

.feature-item:hover {
  color: #00A79D; /* Highlight color on hover */
  transform: translateX(10px);
}

.feature-item:hover::before {
  color: #F3F8F2; /* Change bullet color on hover */
}

.project-underline {
  width: 200px;
  border: 3px solid #019187 !important;
  background-color: transparent;
  margin-top: 5px;
  margin-bottom: 30px;
}

.projectSwiper {
    width: 700px;
    height: 550px; /* Set height of Swiper to full viewport height */
    background-color: #00A79D;
    border-radius: 8px;
    margin-top: 50px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7);
  }
  
  .projectSwiper .project-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    padding: 20px;
    background-color: #00A79D; /* Optional: background color for the slide */
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
    overflow: hidden; /* Ensure no overflow of content outside the slide */
  }
  
  .projectSwiper .project-slider img {
    width: 600px;
    height: auto;
    border-radius: 8px;
    object-fit: cover; /* Ensures the image covers the entire slide without distortion */
  }
  
  .project-slider-caption {
    font-size: 1rem;
    font-weight: bold;
    color: #031D44; /* Customize the caption color */
    margin-top: 20px !important;
  }

  .roles-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    justify-items: left;
    text-align: left;
  }
  
  /* Styling for each role item */
  .role-item {
    font-size: 1.3rem;
    color: #333;
    font-weight: 600;
    line-height: 2;
    transition: all 0.3s ease-in-out; /* Smooth transition for hover effect */
    position: relative;
    padding-left: 30px; /* Space for bullet */
    padding-right: 5px;
  }
  
  /* Styling for the bullet symbol */
  .bullet {
    color: #00A79D !important;
    font-size: 1.6rem; /* Larger bullet for emphasis */
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  
  /* Hover effect for role items */
  .role-item:hover {
    color: #ffffff;
    background-color: #052f3f;
    padding-left: 30px; /* Slight padding increase for a better hover effect */
    padding-right: 20px;
    border-radius: 5px; /* Rounded corners */
  }
  
  /* Responsive layout for smaller screens */
  @media screen and (max-width: 768px) {
    .roles-grid {
      grid-template-columns: repeat(2, 1fr); /* Two columns on medium screens */
    }
  }
  
  @media screen and (max-width: 480px) {
    .roles-grid {
      grid-template-columns: 1fr; /* One column on smaller screens */
    }
  }
  @media (max-width:768px) {
    .projectSwiper{
        width:400px;
        height: 300px;
    }
    
  }
  @media (max-width:500px) {
    .projectSwiper{
        width:300px;
        height: 300px;
    }
    
  }
  
@media (max-width: 768px) {
    .item {
        flex-basis: 45%; /* Two items per row */
    }
    .project-title{
        font-size: 1.7rem;
    }
    .about-project-container p{
        font-size: 14px;
    }
    .item h4{
        font-size: 1.25rem;
    }
    
    .item:nth-child(3) {
        flex-basis: 90%; /* The third item takes 90% width, centering itself */
    }
    .crud-desc-container p{
        font-size: 15px;
        width: 230px;
    }
    .svg-icon2{
        width: 140px;
    }
    .svg-icon{
        width: 100px;
    }
    .seamless{
        font-size: 2.3rem;
    }
    .crud-container p{
        font-size: 20px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .item {
        flex-basis: 45%; /* Two items per row on medium screens */
    }
    
    .item:nth-child(3) {
        flex-basis: 45%; /* Third item still takes half width, aligns like others */
    }
}

@media (min-width: 1025px) {
    .item {
        flex-basis: 30%; /* Three items per row on larger screens */
    }
}
@media (max-width:560px) {
    .border-separator{
        margin: 0px 5px;
    }
    .about-project-heading h4{
        font-size: 20px;
    }
    .about-project-heading h4{
        font-size: 17px;
        line-height: 22px;
    }
    .about-project-container p{
        font-size: 13px;
    }
}

  .property-projectSwiper {
    width: 600px;
    height: 390px; /* Set height of Swiper to full viewport height */
    background-color: whitesmoke;
    border-radius: 8px;
    margin-top: 50px;
    margin-bottom: 50px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7);;
  }
  
  .property-projectSwiper .property-project-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensure no overflow of content outside the slide */
  }
  
  .property-projectSwiper .property-project-slider img {
    width: auto;
    height: auto;
    object-fit: cover; /* Ensures the image covers the entire slide without distortion */
  }
  @media (max-width:768px) {
    .property-projectSwiper{
        width:450px;
        height: 340px;
    }
    
  }
  @media (max-width:500px) {
    .property-projectSwiper{
        width:380px;
        height: 300px;
    }
    
  }
  
  /* Grid styling for the achievements */
  .achievements-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Four equal columns */
    gap: 2rem;
    justify-items: center;
  }
  
  /* Individual achievement item styling */
  .achievement-item {
    text-align: center;
    background: rgb(250, 246, 246); /* Card background */
    border-radius: 12px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
    padding: 2rem 1.5rem;
    transition: transform 0.3s, box-shadow 0.3s;
  }

  .achievement-item:hover {
    transform: scale(1.05); /* Slight scaling */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
  }
  
  /* Styling for the achievement value (e.g., 10,000+) */
  .achievement-value {
    font-size: 1.8rem;
    font-weight: bold;
    color: #052f3f;
    margin-bottom: 0.5rem;
  }
  
  /* Styling for the achievement description */
  .achievement-description {
    font-size: 1.4rem;
    color: #05a398;
    line-height: 1.5;
    font-weight: 600;
  }
  
  /* Responsive layout adjustments */
  @media screen and (max-width: 768px) {
    .achievements-grid {
      grid-template-columns: repeat(2, 1fr); /* Two columns for medium screens */
    }
  }
  
  @media screen and (max-width: 480px) {
    .achievements-grid {
      grid-template-columns: 1fr; /* One column for small screens */
    }
  }