.neksogix{
  color: #00A79D;
  font-size: 3rem;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  display: block;
}
.error{
  color: red;
}
.close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.heading-streamline h2{
  color:#052f3f;
  font-size: 2.35rem;
}
.heading-streamline h3{
  color:#052f3f;
  font-size: 2rem;
}
.accordion-container {
  
  margin: auto;
}

.accordion-item {
  border: none;
  border-radius: 12px;
  margin-bottom: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.accordion-item:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.2);
}

.custom-accordion-button {
  background: none;
  color: #333;
  font-size: 1.15rem;
  font-weight: 600;
  padding: 25px;
  border: none;
  border-radius: 0;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.custom-accordion-button:not(.collapsed) {
  background-color: #00A79D;
  color: #fff;
}

.custom-accordion-button::after {
  transition: transform 0.3s ease;
}

.custom-accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg);
}

.custom-accordion-body {
  padding: 25px;
  background-color: #fff;
  color: #555;
  line-height: 1.8;
  border-top: 2px solid #6c757d;
}

.contact-form-container h2{
  color:#F3F8F2;
  font-size: 3rem;
}
.contact-form-container {
  background-color: #031D44;
  border-radius: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 800px;
}

.contact-form-container:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.2);
}
.custom-select,
.form-control{
  background-color: #F3F8F2;
}
.custom-input,
.custom-select,
.custom-textarea {
  border: 2px solid #dee2e6;
  border-radius: 8px;
  padding: 12px 15px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.custom-input:focus,
.custom-select:focus,
.custom-textarea:focus {
  border-color: #052f3f;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}
.custom-select option:hover {
  background-color: #f0f0f0;
}
.custom-input:hover,
.custom-select:hover,
.custom-textarea:hover {
  border-color: #052f3f;
}

.call-btn, .custom-btn {
  padding: 12px 0;
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 8px;
  background-color: #00A79D;
  color:white;
  border: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.custom-btn:hover {
  background-color: #04c3b6;
  color: white;
  transform: translateY(-2px);
}

.info-mail-container {
  background-color: #031D44;
  border-radius: 10px;
  padding: 20px 50px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease-out, box-shadow 0.3s ease-out, transform 0.3s ease-out; /* Add transition for transform */
}

.info-mail-container:hover {
  background-color: #1F2A47; /* Change background color on hover */
  box-shadow: 0 8px 50px rgba(0, 0, 0, 0.7); /* Modify box shadow on hover */
  transform: scale(1.05); /* Scale the container up slightly */
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000; /* Ensures the modal is above other content */
}
body.modal-open {
  overflow: hidden;
}
/* Modal container */
.modal {
  top: 10px;
  background: #fff;
  padding: 30px;
  margin-bottom: 0;
  border-radius: 8px;
  height: 90%;
  width: 500px;  /* Adjust this to control the width of the modal */
  max-width: 90%;  /* Makes the modal responsive */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s ease-in-out;
}

/* Close button */
.cls-btn {
  position: absolute;
  top: 8px;
  right: 10px;
  color: black;
  border: none;
  font-size: 18px;
  font-weight: bold;
  padding: 3px 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease;
}

/* Modal title */
.modal h2 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 24px;
  color: #333;
}

/* Form container */
.modal-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Form field container */
.form-group {
  margin-bottom: 7px;
}

/* Labels */
.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

/* Input fields */
.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 14px;
}

textarea {
  resize: vertical;
  height: 120px;  /* Height for the 'requirements' textarea */
}

/* Row for Name and Email in one row */
.form-row {
  display: flex;
  gap: 15px;  /* Space between the fields */
}

/* Individual form field inside the row */
.form-row .form-group {
  flex: 1;  /* Makes both fields take equal width */
}

.form-group select {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  color: #333;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: all 0.3s ease;
}

/* Focus state for Time Zone dropdown */
.form-group select:focus {
  border-color: #00A79D; 
  outline: none;
  background-color: #fff;
}

/* Styling for the dropdown options */
.form-group select option {
  padding: 8px;
  font-size: 14px;
  color: #333;
}

/* Adding a hover effect for the dropdown */
.form-group select:hover {
  background-color: #f1f1f1;
}

@media (max-width: 768px) {
  .custom-input,
  .custom-select,
  .custom-textarea {
    font-size: 0.9rem;
  }
  
.contact-form-img img{
  display: none;
}
  .custom-btn {
    font-size: 1rem;
  }
  .heading-streamline h2{
      font-size: 2rem;
  }
  .heading-streamline h3{
      font-size: 1.5rem;
  }
}

@media (max-width: 576px) {
  .custom-input,
  .custom-select,
  .custom-textarea {
    padding: 10px 12px;
    font-size: 0.85rem;
  }

  .custom-btn {
    font-size: 0.95rem;
  }
}

.info-block h4 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.info-block p {
  font-size: 1rem;
  margin-bottom: 0;
}

/* Media query for tablet devices and up */
@media (max-width: 768px) {
  

  .info-block {
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
  }

  .info-block h4 {
    font-size: 1.25rem;
  }

  .info-block p {
    font-size: 0.95rem;
  }
}

/* Media query for mobile devices */
@media (max-width: 576px) {
  .info-block h4 {
    font-size: 1.1rem;
  }

  .info-block p {
    font-size: 0.7rem;
  }

  .info-mail-container {
    padding: 15px;
  }
}
@media (max-width: 768px) {
  .neksogix{
    font-size: 3.5rem;
}
  .accordion-item {
    margin: 5px 10px;
  }

  .custom-accordion-body {
    padding: 20px;
    font-size: 0.95rem;
  }
  .info-mail-container {
    padding: 15px 30px;  /* Reduce padding for medium screens */
  }
}

@media (max-width: 576px) {
  .accordion-item {
    margin: 5px 5px;
  }

  .custom-accordion-button {
    padding: 15px 20px;
    font-size: 0.9rem;
  }

  .custom-accordion-body {
    padding: 15px;
    font-size: 0.9rem;
  }
}