/* .bg-custom-section {
  background-color: #a8eade;
  color: #052f3f;
} */

.blogmain-content{
    padding-left: 10%;
    max-width:fit-content;
    padding-top: 150px !important;
    padding-bottom: 100px !important;
}
.blogmain-content h1{
    font-size: 56px !important;
    font-weight: 700 ;
    color: #052f3f;
}

.blogmain-btn{
    background-color: #00A79D;
    color: white;
    position: relative;
    display: inline-block;
    height: 50px !important;
    width: 170px !important;
    font-size: 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    transition: color 0.4s ease-in-out;
}

.blogmain-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #052f3f ;
    z-index: 0;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s ease-in-out;
}

.blogmain-btn:hover::before {
    transform: scaleX(1);
    transform-origin: left;
}

.blogmain-btn span {
    position: relative;
    z-index: 1;
    transition: transform color 0.4s ease;
}

.blogmain-btn:hover span {
    color: white;
}


.card-date {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 15px;
    padding-top: 10px;
    font-size: 18px;
  }
  
  .card-date i {
    margin-right: 5px;
  }
  
  
  .card-blog {
    background-color: transparent;
    overflow: hidden;
    max-width: 500px;
    max-height: 600px;
  }
  
  .card-image-container-blog {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .card-image-blog {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .card-image-container-blog:hover .card-image-blog {
    transform: scale(1.1);
  }
  
  .card-content-blog {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.card-description-blog {
    flex-grow: 1;
    margin-bottom: 15px;
    font-size: 1.1rem;
    line-height: 1.5;
    overflow: hidden;
    min-height:100px;
}
  
  .card-title-blog {
    font-size: 1.25rem;
    margin-bottom: 15px;
    transition: color 0.3s ease;
    cursor: pointer;
    min-height: 50px;
  }
  
   .card-title-blog:hover {
    color: #00A79D; /* Change this color as needed */
  }
  
  
  .card-button-blog {
    display: inline-block;
  width: 130px; /* Fixed width */
  padding: 10px;
  background-color: #00A79D;
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  }
  
  .card-button-blog:hover {
    background-color: #052f3f;
    transition: background-color 0.4s ease-in-out;
  }
  .blogmain-img{
    padding: 0px 80px 0px 100px;

  }
  @media (max-width:990px) {
    .blogmain-img{
      padding: 0px 120px;
    }
    
  }
  @media (max-width:768px) {
    .blogmain-img{
      display: none;
    }
  }
  
  /* BlogMain.css */
  .category-bar {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  
  /* .category-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
    font-size: 1.25rem;
    color: #052f3f;
  }

  .category-item:hover {
    color: #F3F8F2 !important;
    background-color: #031D44;
    border-radius: 2px;
    width:80%;
    height: 40px;
    transform: translateY(-2px);
  }
   */
  /* .swiper-slide .category-bar{
    display: flex;
    justify-content: center;
    align-items: center;
  }
   */
  





@media (max-width: 1100px) {
    .card-blog {
      max-width: 100%;
    }
  
    .card-title-blog {
      font-size: 1.1rem;
    }
  
    .card-description-blog {
      font-size: 0.9rem;
    }
  
    .card-button-blog {
      font-size: 0.875rem;
      
    }
  }
  
  @media (max-width: 576px) {
    .card-title-blog {
      font-size: 1.1rem;
    }
  
    .card-description-blog {
      font-size: 0.95rem;
    }
  
    .card-button-blog {
      font-size: 0.9rem;
      
    }
  }
  
  @media (max-width: 400px) {
    .card-title-blog {
      font-size: 0.75rem;
    }
  
    .card-description-blog {
      font-size: 0.625rem;
    }
  
    .card-button-blog {
      font-size: 0.625rem;
    
    }
  }
   
  