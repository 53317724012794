
.project-text {
    font-size: 40px;
    letter-spacing: 4px;
    font-weight: 400;
    font-weight: bold;
}

.project-more-container {
    display: flex;
    justify-content: start;
    align-items: center;
}
.project-container {
    position: relative;
    overflow: hidden; /* To ensure the sliding image doesn't overflow the container */
}

.background-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00A79D;
    /* background-image: url('../assets/Background.jpg');
    background-size: auto; 
    background-attachment: fixed; 
    background-position: 45%; 
    background-repeat: no-repeat; 
    opacity: 0.9; */
}

/* Media query for smaller screens to ensure proper wrapping */
@media (max-width: 768px) {
    .background-image-container {
        background-size: cover; /* Ensures the image still covers the entire container on smaller screens */
        background-position: center; /* Centers the image */
    }
}

/* Additional media query if you want specific behavior on very small devices */
@media (max-width: 480px) {
    .background-image-container {
        background-size: cover; /* Cover the entire container */
        background-position: center; /* Centers the image */
    }
}


/* Content styling */
.project-container .row {
    position: relative;
    z-index: 2; /* Ensure content appears above the background image */
}


.project-btn {
    font-size: 18px;
    font-weight: 400;
    background: linear-gradient(to right, rgba(100, 200, 200, 1), rgba(100, 200, 200, 1)),
                linear-gradient(to right, rgba(255, 0, 0, 1), rgba(255, 0, 180, 1), rgba(0, 100, 200, 1));
    background-size: 100% 3px, 0 3px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;
}

.project-btn:hover {
    background-size: 0 3px, 100% 3px;
}

.project-more {
    font-size: 25px;
    color: #031D44;
    font-style: italic;
    letter-spacing: 2px;
    font-weight: 400;
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.project-more .arrow-icon-project {
    display: inline-block;
    margin-left: 10px;
    transition: transform 0.3s ease;
    font-size: 20px;
}

.project-more:hover .arrow-icon-project {
    transform: translateX(5px);
}

.list-container {
    background-color: #031D44 !important;
    padding: 20px;
    border: 2px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    transition: transform 0.5s ease, box-shadow 0.3s ease;
}

.list-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.learn-project-btn {
    background: linear-gradient(to right, rgb(80, 153, 153), rgba(100, 200, 200, 1)),
                linear-gradient(to right, #199C92, rgb(74, 218, 98), #00A79D);
    background-size: 100% 3px, 0 3px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;
}

.learn-project-btn:hover {
    background-size: 0 3px, 100% 3px;
}

.learn-project-more {
    font-size: 15px;
    color: #00A79D;
    font-style: italic;
    letter-spacing: 2px;
    font-weight: 500;
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.learn-project-more .arrow-icon {
    display: inline-block;
    margin-left: 10px;
    transition: transform 0.3s ease;
    font-size: 20px;
}

.learn-project-more:hover .arrow-icon {
    transform: translateX(5px);
}

@media (max-width: 990px) {
    .project-text {
        font-size: 25px;
        transition: font-size 0.3 ease;
    }
    .project-more {
        font-size: 19px;
    }
}
