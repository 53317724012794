/* Global Styles */
.bg-custom-section {
    /* background: linear-gradient(to bottom, #ffffff, #51ede3); */
    background-color: #00A79D;
}

.main-content {
    padding-left: 7%;
    max-width: fit-content;
    padding-top: 150px;
    padding-bottom: 100px;
}
.main-content h1 span{
    color: #F3F8F2; /* A vibrant color for the company name */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Adds a shadow for depth */
  display: inline-block;
  transition: transform 0.3s ease;
  font-size: 55px;
}

@media (max-width:990px) {
    .blogmain-img{
      padding: 0px 120px;
    }
    
  }
  @media (max-width:768px) {
    .blogmain-img{
      display: none;
    }
  }

.main-content h1 {
    font-size: 54px;
    font-weight: 700;
    color: rgb(7, 7, 7);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.main-btn {
    background-color: #031D44;
    color: white;
    position: relative;
    display: inline-block;
    height: 60px;
    width: 180px;
    font-size: 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    transition: color 0.4s ease-in-out;
}

.main-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #052f3f;
    z-index: 0;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s ease-in-out;
}

.main-btn:hover::before {
    transform: scaleX(1);
    transform-origin: left;
}

.main-btn span {
    position: relative;
    z-index: 1;
    transition: transform color 0.4s ease;
}

.main-btn:hover span {
    color: white;
}
@media (max-width:768px) {
    .main-content h1{
        font-size: 45px;
    }
    .main-content h1 span{
        font-size: 50px;
    }
}
/* About Section */

.about-underline {
    width: 200px;
    border: 3px solid #019187 !important;
    background-color: transparent;
    margin-top: 5px;
    margin-bottom: 30px;
    margin-left: 542px;
}
.about-us{
    color:#052f3f;
    text-shadow: 2px 4px 2px rgba(2, 53, 77, 0.212);
    font-size: 46px;
    text-align: center;
}

.about-heading {
    font-size: 45px;
    font-weight: 700;
    color: #00A79D;
    line-height: 1.2;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for depth */
    
}

.about-paragraph {
    font-size: 22px;
    color: #333;
    line-height: 1.6;
    margin-top: 2px;
    text-align: justify; /* Justify text for a neat appearance */
     /* Adds slight spacing between letters */
    
}
@media (max-width:768px) {
    .about-paragraph {
        font-size: 20px;
    }
    .about-heading{
        font-size: 40px;
    }
    
}

/* Core Values Section */
.core-values-section {
    /* background: linear-gradient(to left, #083d52, #00A79D); */
    background-color: #031D44;
    padding: 80px 20px; /* Reduced horizontal padding for better mobile responsiveness */
    color: white;
    text-align: center; /* Center align text for a cleaner look */
}

.core-values-section h1 {
    color: white;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4); /* Stronger shadow for better readability */
    font-size: 50px;
    margin-bottom: 20px;
    font-weight: 500;
    text-transform: uppercase; /* Add uppercase for a bold statement */
}

.core-values-description {
    font-size: 25px;
    font-weight: 400;
    color: white;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
    margin-bottom: 40px;
    padding: 0 10px; /* Add padding for better readability on smaller screens */
}

.core-values-content {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    row-gap: 40px; /* Add space between rows on smaller screens */
    box-sizing: border-box;
}

.core-value-item {
    padding: 0 20px;
    border-left: 2px solid rgba(255, 255, 255, 0.5); /* Lighter border for a softer look */
}

.core-value-item:first-child {
    border-left: none; /* Remove left border on the first item */
}

.core-value-item h3 {
    font-size: 25px;
    font-weight: 600;
    color: white;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase; /* Make the value titles uppercase for emphasis */
}

.core-value-item p {
    font-size: 18px;
    line-height: 1.6;
    color: #f2f2f2; /* Slightly lighter color for text for readability */
    text-align: center;
    margin-bottom: 0;
}

/* Responsive adjustments for Core Values */
@media (max-width: 768px) {
    .core-values-section {
        padding: 60px 30px; /* Adjust padding for smaller screens */
    }

    .core-values-section h1 {
        font-size: 40px; /* Reduce heading size for better fit */
    }

    .core-values-description {
        font-size: 20px; /* Adjust description text size */
    }

    .core-values-content {
        flex-direction: column; /* Stack items vertically */
        row-gap: 20px; /* Reduce space between rows on smaller screens */
    }

    .core-value-item {
        border-left: none; /* Remove border on smaller screens */
        border-bottom: 2px solid rgba(255, 255, 255, 0.5); /* Add bottom border instead */
        padding-bottom: 20px;
        margin-bottom: 20px;
        width: 100%; /* Ensure items take up the full width */
        box-sizing: border-box;
    }

    .core-value-item:last-child {
        margin-bottom: 0; /* Remove the margin for the last item */
        border-bottom: none; /* No bottom border on the last item */
    }

    .core-value-item h3 {
        font-size: 24px; /* Adjust value title size for better fit */
    }

    .core-value-item p {
        font-size: 16px; /* Adjust value description size */
    }
}
.our-edge-section {
    /* background: linear-gradient(to bottom, #ffffff 20%, #00A79D); */
    background: radial-gradient(circle at center, #00A79D 1%,#ffffff 70%);
    padding: 60px 20px;
}

.our-edge {
    color:#031D44;
    text-shadow: 2px 4px 2px rgba(2, 53, 77, 0.212);
    font-size: 46px;
    position: relative;
}

.our-edge::after {
    content: '';
    width: 200px;
    height: 3px;
    background-color: #00A79D;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.edge-card {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 530px;
    text-align: center;
}

.edge-card h5 {
    font-size: 25px;
    font-weight: 600;
    color: #031D44;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 15px;
    font-style: italic;
}

.edge-card p {
    font-size: 16px;
    color: #606060;
    line-height: 1.6;
}

.edge-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .edge-card {
        max-width: 90%;
    }
    .our-edge{
        font-size: 35px;
    }
    .our-edge::after{
        width:100px;
    }
    .our-edge-title img{
        width: 100px;
    }
    .edge-card p{
        font-size: 16px;
    }
    
}




/* Team Section */
.team-section {
    text-align: center;
    padding: 50px 0;
    background-color: #ffffff;
}

.section-title {
    font-size: 38px;
    font-weight: 600;
    color: #00A79D;
    margin-bottom: 30px;
    text-transform: uppercase;
}

.section-subtitle {
    font-size: 30px;
    font-weight: 600;
    color: #333;
    margin-bottom: 30px;
}

.section-description {
    font-size: 18px;
    color: #666;
    margin-bottom: 30px;
}

.team-members {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 equal columns to control placement */
    grid-template-rows: auto auto; /* Two rows */
    grid-gap: 30px; /* Adjust gap between grid items */
    justify-content: center;
    align-items: center;
}

.team-member:nth-child(1) {
    grid-column: 2 / span 1; /* Position first image in the 2nd column */
}

.team-member:nth-child(2) {
    grid-column: 4 / span 1; /* Position second image in the 4th column */
}


.team-member-image {
    width: 250px; /* Adjust the size as needed */
    height: 250px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.team-member-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.team-member-info {
    margin-top: 10px;
}

.team-member-name {
    font-size: 23px;
    font-weight: 700;
    color: #040404;
    margin-bottom: 7px;
}

.team-member-position {
    font-size: 20px;
    color: #060606;
    margin: 0 10px;
}
.team-member-title {
    font-size: 23px;
    font-weight: 500;
    color: #060606;
    margin-top: 3px;
}
