/* .bg-custom-section {
  background-color: #a8eade;
  color: #052f3f;
} */
.swiper-pagination-bullet-active {
  background-color: #00A79D;
  /* This changes the active dot color */
}

.blogmain-content {
  padding-left: 10%;
  max-width: fit-content;
  padding-top: 80px;
  padding-bottom: 80px;
}

.blogmain-content h1 {
  font-size: 60px;
  font-weight: 700;
  color: #052f3f;
}

.blogmain-btn {
  background-color: #00A79D;
  color: white;
  position: relative;
  display: inline-block;
  height: 60px;
  width: 180px;
  font-size: 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
  transition: color 0.4s ease-in-out;
}

.blogmain-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #052f3f;

  z-index: 0;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.4s ease-in-out;
}

.blogmain-btn:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

.blogmain-btn span {
  position: relative;
  z-index: 1;
  transition: transform color 0.4s ease;
}

.blogmain-btn:hover span {
  color: white;
}


.card-date {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 15px;
  padding-top: 10px;
  font-size: 18px;
}

.card-date i {
  margin-right: 5px;
}


.card-blog {
  background-color: transparent;
  overflow: hidden;
  max-width: 500px;
  max-height: 600px;
}

.card-image-container-blog {

  position: relative;
  overflow: hidden;
  border-radius: 10px;


}

.card-image-blog {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;

}

.card-image-container-blog:hover .card-image-blog {
  transform: scale(1.1);
}

.card-content-blog {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card-description-blog {
  flex-grow: 1;
  margin-bottom: 15px;
  font-size: 1.1rem;
  line-height: 1.5;
  overflow: hidden;
  min-height: 100px;
}

.card-title-blog {
  font-size: 1.25rem;
  /* margin-bottom: 15px; */
  transition: color 0.3s ease;
  cursor: pointer;
  min-height: 10px;
}

.card-title-blog:hover {
  color: #00A79D;
  /* Change this color as needed */
}


.card-button-blog {
  /* margin-top: -10px; */
  display: inline-block;
  width: 130px;
  /* Fixed width */
  padding: 10px;
  background-color: #00A79D;
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.card-button-blog:hover {
  background-color: #052f3f;
  transition: background-color 0.4s ease-in-out;
}

.blogmain-img {
  padding: 0px 80px 0px 100px;

}

@media (max-width:990px) {
  .blogmain-img {
    padding: 0px 120px;
  }

}

@media (max-width:768px) {
  .blogmain-img {
    display: none;
  }
}

/* BlogMain.css */
.category-bar {
  margin-top: 80px;
  margin-bottom: 60px;
}

.category-item {
  padding: 12px 20px;
  background-color: #f2f2f2;
  /* Default light background */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  color: #052f3f;
  /* Default dark text */
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  box-sizing: border-box;
}

.category-item:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  background-color: #00A79D;
  /* Hover background color */
  color: #fff;
  /* Ensure text is white on hover */
}

.category-item.active {
  background-color: #00A79D;
  /* Active state background */
  color: #fff;
  /* Active state text color */
}

.category-item.active:hover {
  background-color: #007c74;
  /* Darker shade of the active background on hover */
}

.category-icon {
  font-size: 1.4rem;
  margin-right: 8px;
}

.category-name {
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle;
}


.swiper-slide .category-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}







@media (max-width: 1100px) {
  .card-blog {
    max-width: 100%;
  }

  .card-title-blog {
    font-size: 1.1rem;
  }

  .card-description-blog {
    font-size: 0.9rem;
  }

  .card-button-blog {
    font-size: 0.875rem;

  }
}

@media (max-width: 576px) {
  .card-title-blog {
    font-size: 1.1rem;
  }

  .card-description-blog {
    font-size: 0.95rem;
  }

  .card-button-blog {
    font-size: 0.9rem;

  }
}

@media (max-width: 400px) {
  .card-title-blog {
    font-size: 0.75rem;
  }

  .card-description-blog {
    font-size: 0.625rem;
  }

  .card-button-blog {
    font-size: 0.625rem;

  }
}

