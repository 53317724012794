/* Login.css */


.login-outer {
   
    background: linear-gradient(135deg, #00A79D, #052f3f);
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.register-now{
    color: #00A79D;
    font-weight: 500;
}
.register-now:hover{
    color: #052f3f;
}

.login-container {
    background: #f9f9f9;
    backdrop-filter: blur(10px);
    padding: 40px;
    border-radius: 10px;
    box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 450px;
    text-align: center;
    color: #333;
}

.login-title {
    font-size: 35px;
    margin-bottom: 10px;
    font-weight: bold;
    color: #052f3f;
}
.login-title span{
    color: #00A79D;
    font-size: 36px
}

.login-subtitle {
    font-size: 16px;
    margin-bottom: 30px;
    color: #666;
}

.login-form-group {
    margin-bottom: 20px;
    text-align: left;
}

.login-form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    color: #555;
}

.login-control {
    width: 100%;
    padding: 12px 15px;
    font-size: 14px;
    border-radius: 30px;
    border: 1px solid #ddd;
    background: #f9f9f9;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0,)
}