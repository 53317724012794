@font-face {
    font-family: 'HarperBold';
    src: url('./assets/Harper-Bold Personal Use.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.custom-font {
    font-family: 'HarperBold', poppins;
}
