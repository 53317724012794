/* .pagination {
    display: flex;
    justify-content: center;
    margin-top:20px;
  }
  
  .page-item {
    margin: 0 5px;
    padding: 15px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .page-item.active {
    background-color: #00A79D;
    color: white;
    border-color: #00A79D;
  }
  
  .page-item:hover:not(.active) {
    background-color: #f0f0f0;
  } */
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-top: 20px;
  }
  
  .page-item {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .page-item.active {
    background-color: #00A79D;
    color: white;
  }
  
  .page-item:hover {
    background-color: #00A79D;
    color: white;
  }
  
  .page-item.prev, .page-item.next {
    cursor: pointer;
  }
  
  .page-item.disabled {
    color: #999;
    cursor: not-allowed;
  }
  