


.blog {
  background: linear-gradient(to left,#9be7e2,#FFFFFF);
  
}

.heading span {
  color: #00A79D; /* A vibrant color for the company name */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Adds a shadow for depth */
  display: inline-block;
  transition: transform 0.3s ease;
  font-size: 50px;
}

.blog-content {
  color: #052f3f;
  margin-top: -50px; /* Adjusted margin to move content closer to the top */
  padding-top: 0px;
}

.about-btn {
  background-color: #00A79D;
  color: white;
  position: relative;
  display: inline-block;
  height: 50px;
  width: 140px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  overflow: hidden;
  transition: color 0.4s ease-in-out;
  margin-top: 25px; /* Moved the button slightly down */
}

.about-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #052f3f;
  z-index: 0;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.4s ease-in-out;
}

.about-btn:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

.about-btn span {
  position: relative;
  z-index: 1;
  transition: transform color 0.4s ease;
}

.about-btn:hover span {
  color: white;
}

.swiper-bg {
  background-color: white;
  padding-top: 0;
  margin-top: -70px;
}

.swiper-image {
  width: auto;
  max-width: 35%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.heading {
  margin-top: 0px;
  font-size: 40px;
  font-weight: bold;
  padding-bottom: 20px;
}
.intro-para{
  font-size: 19px;
  
}

/* Media Queries for Responsive Adjustments */
@media (max-width: 1024px) {
  .heading {
    font-size: 1.75rem;
  }
  .heading span{
    font-size: 2.15rem;
  }

  .intro-para {
    font-size: 1rem;
  }

  .about-btn {
    font-size: 1rem;
    height: 48px;
    width: 140px;
  }

  .swiper-image {
    max-width: 30%;
  }
}

@media (max-width: 768px) {
  .blog-content {
    margin-top: -1rem;
  }

  .heading {
    font-size: 1.5rem;
  }
  .heading span{
    font-size: 2rem;
  }

  .intro-para {
    font-size: 1rem;
  }

  .about-btn {
    font-size: 1rem;
    
  }

  

  .swiper-image {
    max-width: 35%;
  }
}

@media (max-width: 480px) {
  .blog-content {
    margin-top: -0.5rem;
  }

  .heading {
    font-size: 1.25rem;
  }
  .heading span {
    font-size: 1.8rem;
  }

  .intro-para {
    font-size: 1rem;
  }

  .about-btn {
    font-size: 1rem;
    
  }

  .social-links {
    gap: 10px; /* Added space between social links */
  }

  .social-links a {
    font-size: 0.75rem;
  }

  .swiper-image {
    max-width: 40%; /* Reduce size of Swiper images on mobile */
  }
}

/* Mobile Specific Styles */
@media (max-width: 600px) {
  .blog-img img {
    display: none;
  }

  .social-links {
    padding-left: 10px;
    gap: 10px;
    margin-top: -20px;
  }

  .about-btn {
    margin-top: 15px;
  }
}
