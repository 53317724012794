@font-face {
    font-family: 'HarperBold';
    src: url('../assets/Harper-Bold\ Personal\ Use.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.custom-font {
    font-family: 'HarperBold', sans-serif;
}
.swiper-pagination-bullet-active {
    background-color: #00A79D; /* This changes the active dot color */
  }
.testimonials-section {
    background: linear-gradient(135deg, #f5fcfc 40%, #e0f7f7);
    padding: 3rem 1.5rem;
    padding-bottom: 1rem;
    text-align: left; /* Change to left alignment */
    position: relative;
    overflow: hidden;
}

.testimonials-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    margin-left: 2rem;
    color: #333333;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left; /* Align the heading to the left */
}

.highlight {
    color: #00A79D; /* Main color */
}

.navigation-buttons {
    position: absolute;
    top: 3rem;
    right: 5rem; /* Align buttons to the right */
    display: flex;
    gap: 1rem;
}

.arrow-button {
    background-color: #ffffff;
    border: 3px solid #00A79D;
    border-radius: 50%;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
    font-size: 1.4rem;
    padding: 0.6rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00A79D;
}

.arrow-button:hover {
    background: linear-gradient(135deg, #00A79D, #00c9b7);
    color: #ffffff;
    transform: scale(1.2);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.testimonials-cards-container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: nowrap;
    padding: 10px;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.testimonial-card {
    background: linear-gradient(145deg, #ffffff, #f0f8f8);
    border-radius: 20px;
    padding: 2.5rem;
    width: 370px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    text-align: left;
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    position: relative;
    border-top: 6px solid #00A79D;
    overflow: hidden;
}
.testimonial-details {
    display: flex;
    align-items: flex-start;
    gap: 1rem; /* Space between the vertical line and the text */
    padding-left: 1.2rem; /* Adjust spacing for alignment */
    border-left: 3px solid #00A79D; /* Vertical line on the left */
}

.testimonial-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.card-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;
    gap: 0.8rem;
    position: relative;
}

.testimonial-icon {
    font-size: 2.6rem;
    color: #00A79D;
    background: linear-gradient(145deg, #00A79D, #00c9b7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.quote-icon {
    font-size: 2.0rem;
    color: #00A79D;
    margin-bottom: 1rem;
}

.testimonial-quote {
    font-size: 1rem;
    font-style: italic;
    color: #444;
    margin: 1.8rem 0;
    line-height: 1.8;
    position: relative;
    z-index: 1;
}

.testimonial-name {
    font-size: 1.3rem;
    font-weight: bold;
    /* margin-top: 0.9rem; */
    color: #333333;
}

.testimonial-role,
.testimonial-location {
    font-size: 0.9rem;
    color: #777;
    
}

/* Responsive Design */
@media (max-width: 768px) {
    .testimonials-title {
        font-size: 2.2rem;
    }

    .navigation-buttons {
        top: 2rem;
        right: 1rem;
    }

    .testimonials-cards-container {
        flex-wrap: wrap;
        gap: 1.5rem;
    }

    .testimonial-card {
        width: 100%;
    }
}
