
.navbar-nav .nav-item .nav-link {
  transition: background-color 0.3s ease;
}
.nav-item{
  padding:0px 12px;
  position: relative;
}

.navbar-nav{
  font-size: 16px;
}
.navbar-nav.shrink {
  font-size: 14px; /* Smaller font size when search bar expands */
  transition: font-size 0.3s ease; /* Smooth transition */
}

@media (max-width:1150px) {
.navbar-nav.shrink {
  font-size: 13px; /* Smaller font size when search bar expands */
  transition: font-size 0.3s ease; /* Smooth transition */
  
}
.search-container{
margin-right: 0px;
}
.nav-item{
padding: 0px 4px;
}
.navbar-nav{
font-size: 15px;
}
}



@media (max-width: 1200px) {
.responsive-logo {
    width: 45px;
}
.responsive-title{
  font-size: 1.45rem;
}
}

/* Small screens (≥576px and <768px) */
@media (max-width: 768px) {
.responsive-logo {
    width: 50px;
}
.responsive-title {
  font-size: 1.75rem;
}
.responsive-title {
      font-size: 1.5rem;
  }
}

/* Extra small screens (<576px) */
@media (max-width: 456px) {
.responsive-logo {
    width: 40px;
}
.responsive-title {
  font-size: 1.25rem;
}
}
.title{
  color: #00A79D;
  
}


.navbar-nav .nav-item .nav-link:hover {
  color: #00A79D;
  border-radius: 5px;
  transition: transform 0.3s ease;
  
}


.navbar{
  background-color:rgb(253, 251, 251);
  height: 90px;
}


/* Button.css */

.login-btn{
background-color: #199c92;
color: white;
position: relative;
        display: inline-block;
        height: 40px;
        width: 100px;
        border-radius: 10px;
        font-size: 14px;
        cursor: pointer;
        overflow: hidden;
        transition: color 0.4s ease-in-out;
        font-weight: bold;
}
.call-btn{
  background-color: #199c92;
  color: white;
  position: relative;
          display: inline-block;
          height: 50px;
          width: 200px;
          border-radius: 10px;
          font-size: 16px;
          cursor: pointer;
          overflow: hidden;
          transition: color 0.4s ease-in-out;
          font-weight: bold;
  }
.login-btn::before {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
border-radius: 10px;
background-color:#052f3f;
border: 1px solid white;
z-index: 0;
transform: scaleX(0);
transform-origin: right;
transition: transform 0.4s ease-in-out;
}
.call-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color:#052f3f;
  border: 1px solid white;
  z-index: 0;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.4s ease-in-out;
  }

.login-btn:hover::before {
transform: scaleX(1);
transform-origin: left;
}
.call-btn:hover::before {
  transform: scaleX(1);
  transform-origin: left;
  }

.login-btn span {
position: relative;
z-index: 1;
transition: transform color 0.4s ease;
}
.call-btn span {
  position: relative;
  z-index: 1;
  transition: transform color 0.4s ease;
  }

.login-btn:hover span {
color:white;
}
.call-btn:hover span {
  color:white;
  }

.search-icon:hover{
  color: #00978D;

}
/* Container for the search */
.search-container {
position: relative;
display: flex;
align-items: center;
}

/* Search icon styling */
.search-icon {
cursor: pointer;
transition: width 0.4s ease;
font-size: 25px;
}

/* Hidden search input initially */
.search-input {
width: 0;
opacity: 0;
transition: width 0.4s ease, opacity 0.4s ease;
border: none;
outline: none;
border-bottom: 2px solid #00A79D;
background: transparent;
color: #000;
margin-left: 5px;
}

/* Expanded search input */
.search-container.expanded .search-input {
width: 120px;
opacity: 1;
}

.search-input:focus {
border-bottom-color: black;
}
@media (max-width: 576px) {
.search-icon:hover{
  color: #00978D;

}
/* Container for the search */
.search-container {
position: relative;
display: flex;
align-items: center;
}

/* Search icon styling */
.search-icon {
cursor: pointer;
transition: width 0.4s ease;
font-size: 26px;
}

/* Hidden search input initially */
.search-input {
width: 0;
opacity: 0;
transition: width 0.4s ease, opacity 0.4s ease;
border: none;
outline: none;
border-bottom: 2px solid #00A79D;
background: transparent;
color: #000;
margin-left: 5px;
}

/* Expanded search input */
.search-container.expanded .search-input {
width: 120px;
opacity: 1;
}

.search-input:focus {
border-bottom-color: black;
}
}

/* Dropdown menu base styling */
.dropdown-menu {
display: none;
opacity: 0;
transform: translateY(10px);
transition: opacity 0.3s ease, transform 0.3s ease;
position: absolute;
top: 100%;
left: 0;
z-index: 1000;
background-color: #ffffff;
border-radius: 5px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
padding: 10px 0;
}

/* Show dropdown when hovered */
.dropdown-menu.show {
display: block;
opacity: 1;
transform: translateY(0);
}

/* Create a 2-column grid layout for the dropdown */
.dropdown-grid {
display: grid;
grid-template-columns: 1fr 1fr;
gap: 10px;
padding: 10px;
}

/* Dropdown item styling */
.dropdown-item {
padding: 10px 20px;
transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
font-size: 16px;
color: #333;
white-space: nowrap;
}

/* Hover effect for dropdown items */
.dropdown-item:hover {
background-color: #00A79D;
color: white;
transform: translateX(10px);
}

/* Hover effect for nav link */
.hover-color {
color: #00A79D;
}



/* General styling for the drawer navigation */
.drawer-nav {
list-style: none;
padding: 0;
margin: 0;
}

/* Drawer navigation item */
.drawer-nav-item {
padding: 8px 0;
width: 100%;
}

/* Drawer navigation link */
.drawer-nav-link {
font-size: 18px;
padding: 12px 20px; /* Adjusted padding for better spacing */
color: #333;
text-decoration: none;
display: block;
transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

/* Hover effect for drawer navigation link */
.drawer-nav-link:hover, .drawer-hover-color {
color: white;
background-color: #00A79D; /* Light background on hover */
transform: translateX(5px); /* Slight move effect on hover */
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
}

/* Dropdown menu in the drawer */
.drawer-dropdown-menu {
display: none;
background-color: #ffffff;
padding: 0;
margin: 0;
list-style: none;
width: 100%;
border-top: 1px solid #ddd;
transition: max-height 0.5s ease, opacity 0.5s ease;
max-height: 0;
overflow: hidden;
opacity: 0;
}

/* Show dropdown menu when hovered */
.drawer-dropdown-menu.drawer-show {
display: block;
max-height: 500px; /* Set an appropriate max height */
opacity: 1;
}

/* Dropdown menu item */
.drawer-dropdown-item {
padding: 8px 15px; /* Adjusted padding */
font-size: 16px;
color: #333;
text-decoration: none;
display: block;
border-radius: 5px; /* Rounded corners for dropdown items */
transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

/* Hover effect for dropdown item */
.drawer-dropdown-item:hover {
background-color: #00A79D;
color: white;
transform: translateX(5px); /* Slight move effect on hover */
}
/* Login button styling */
.drawer-login-button {
font-size: 18px;
padding: 12px 20px;
color: #fff;
background-color: #00A79D;
border: none;
border-radius: 5px;
cursor: pointer;
transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
display: block;
width: 100%;
text-align: center;
}

/* Hover effect for login button */
.drawer-login-button:hover {
background-color: #052f3f;
color:white;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
