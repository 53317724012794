.footer-area {
  position: relative; /* Make the container relative to position the overlay */
  background-image: url('../assets/Schedule.jpg');
  background-size: cover; /* Ensure the image covers the entire container */
  background-position: center; /* Center the image within the container */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  z-index: 1; /* Ensure it's above any other background or elements */
}

.footer-area::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black; /* You can adjust this to a darker color */
  opacity: 0.4; /* Controls the darkness of the overlay */
  z-index: 2; /* Places the overlay above the background image but below the text */
}

.footer-content {
  color: white; /* White color ensures good contrast on a dark background */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); /* Stronger shadow for better readability */
  display: inline-block;
  transition: transform 0.3s ease;
  gap: 15px;
  max-width: 800px;
  position: relative; /* Ensures the text is above the overlay */
  z-index: 3; /* Places the text above the overlay */
  font-size: 1.5rem; /* Adjust font size for readability */
  line-height: 1.6; /* Improves readability with better spacing */
}
.footer{
  background: linear-gradient(180deg, #052f3f, #053545, #00a79d);
  margin: 0;
  padding: 0;
}


.footer-btn {
    background-color: #00A79D;
    color: white;
    position: relative;
    display: inline-block;
    height: 50px;
    width: 130px;
    font-size: 16px;
    font-weight: 100px bold;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    transition: color 0.4s ease-in-out;
}

.footer-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: white;
    border: 1px solid #00A79D;
    z-index: 0;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s ease-in-out;
}

.footer-btn:hover::before {
    transform: scaleX(1);
    transform-origin: left;
}

.footer-btn span {
    position: relative;
    z-index: 1;
    transition: transform color 0.4s ease;
}

.footer-btn:hover span {
    color: #00A79D;
}

.contact-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative; /* Ensures the text is above the overlay */
    z-index: 3; /* Places the text above the overlay */
}

.contact-form {
    padding: 5px;
    border-radius: 10px;
    background-color: #052f3f;
    border: 2px solid #ffffff; /* White border around the form */
    width: 100%;
    max-width: 350px; /* Adjust this value to decrease the form size */
    box-shadow: 0 4px 8px #ffffff; /* Box shadow */
    margin-top: 40px; /* Adjust this value to move the form down */
}

.contact-form h1 {
    color: white;
}

.form-label {
    color: white;
}

.form-control {
    background-color: #f8f9fa;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
}

.button-container {
    display: flex;
    justify-content: center; /* Centers the button horizontally */

}

.submit-btn {
    background-color: #00A79D;
    color: white;
    position: relative;
    display: inline-block;
    height: 50px;
    width: 100px;
    font-size: 16px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    transition: color 0.4s ease-in-out;
}

.submit-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: white;
    border: 1px solid #052f3f;
    z-index: 0;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s ease-in-out;
}

.submit-btn:hover::before {
    transform: scaleX(1);
    transform-origin: left;
}

.submit-btn span {
    position: relative;
    z-index: 1;
    transition: transform color 0.4s ease;
}

.submit-btn:hover span {
    color: #00A79D;
}

.review-card {
    background-color: #052f3f;
    padding: 20px;
    border-radius: 8px;
    margin: 0 auto;
    max-width: 80%;
    border: 2px solid white;
    box-shadow: 0 4px 8px #ffffff;
    text-align: center;
  }
  
  .rating-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 8px;
  }
  
  .rating-number {
    font-size: 1.4rem;
    color: #ffffff;
    margin: 0;
    font-style: italic;
  }
  
  .stars {
    display: flex;
    gap: 5px;
    color: #ffcc00;
  }
  
  .stars i {
    font-size: 1rem;
  }
  
  .rating-bar-container {
    width: 100%;
    background-color: #fff6f6;
    border-radius: 8px;
    overflow: hidden;
    height: 6px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .rating-bar {
    background-color: #ffcc00;
    height: 100%;
    transition: width 0.3s ease;
  }
  
  .rating-text {
    color: white;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .rating-text p {
    margin: 0;
    font-weight: bold;
  }

  .explorelist{
    font-size: 17px;
    padding-top: 20px;
  }

  .copyright-bar {
    position: relative;
    bottom: 0; /* Fixes the bar at the bottom of the screen */
    width: 100%; /* Ensures it stretches across the width of the screen */
    color: white;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 50px;
    font-size: 15px;
  }
  
  .review-title{
    display: inline-flex;
  align-items: center;
  gap: 10px;
  }
  .footer-link {
    text-decoration: none;
    color: white; /* Default text color */
    display: block; /* Makes the anchor tags block-level for better spacing */
    padding: 4px 0; /* Adds padding to each link */
    font-size: 15px;
    transition: color 0.3s ease, background-color 0.3s ease; /* Smooth transitions */
  }

  .footer-link:hover {
    color: #8dd3ce; /* Change text color on hover */
  
  }
  
  
  .fa-icons:hover {
    transition: transform 0.3s ease, color 0.3s ease;
    transform: scale(1.1); /* Slightly enlarge the icon */
    cursor: pointer;
  }
  
  .fa-fb:hover {
    
    color: #3b5998; /* Change color on hover (example: Facebook color) */
  }
  
  /* Add specific colors for other icons */
  .fa-twitter:hover {
    color: #1da1f2; /* Twitter color */
  }
  
  .fa-instagram:hover {
    color: #e4405f; /* Instagram color */
  }
  
  .fa-linkedin:hover {
    color: #0077b5; /* LinkedIn color */
  }
  